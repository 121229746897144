import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import {
	BAD_REQUEST,
	NO_CONTENT,
} from '@atlassian/jira-common-constants/src/http-status-codes.tsx';
import { TRACE_ID_HEADER } from '@atlassian/jira-fetch/src/utils/constants.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { getDefaultOptions } from '@atlassian/jira-fetch/src/utils/fetch-default-options.tsx';
import { transformValidationErrorFromServer } from '@atlassian/jira-fetch/src/utils/requests.tsx';

const VALIDATION_ERROR_KEY = 'validation.error';
export class JsdFetchError extends FetchError {
	reasonKey: string | undefined;

	response: Response | undefined;

	constructor(statusCode: number, message?: string, reasonKey?: string, response?: Response) {
		super(
			statusCode,
			isEmpty(message) ? `Fetch call failed with status code: ${statusCode}` : message,
		);
		this.name = 'JsdFetchError';

		if (!isUndefined(reasonKey)) {
			this.reasonKey = reasonKey;
		}

		if (!isUndefined(response)) {
			this.response = response;
		}
	}
}
/**
 * Transform Error Object with field errors from JIRA to a message
 */
type BadRequestError = {
	errorMessage: string;
};

const transformMessageErrorsFromServer = (errors: BadRequestError[] = []): string =>
	errors
		.reduce<string[]>((messages: string[], { errorMessage }: BadRequestError) => {
			messages.push(errorMessage);
			return messages;
		}, [])
		.join(' ');

const putOptions = {
	method: 'PUT',
} as const;

const postOptions = {
	method: 'POST',
} as const;

const deleteOptions = {
	method: 'DELETE',
} as const;

const patchOptions = {
	method: 'PATCH',
} as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncResult = Promise<any>;

const processResponse = async (response: Response): Promise<AsyncResult> => {
	if (BAD_REQUEST === response.status) {
		const jsonResponse = await response.json();
		const { errors, reasonKey } = jsonResponse;
		if (reasonKey === VALIDATION_ERROR_KEY) {
			throw transformValidationErrorFromServer(jsonResponse);
		}

		const message = transformMessageErrorsFromServer(errors);
		throw new JsdFetchError(response.status, message, reasonKey, jsonResponse);
	}

	if (!response.ok) {
		const traceId = response.headers.get(TRACE_ID_HEADER);
		if (traceId != null && traceId !== '') {
			throw new FetchError(response.status, `Error server response: ${response.status}`, traceId);
		} else {
			throw new FetchError(response.status, `Error server response: ${response.status}`);
		}
	}

	if (response.status === NO_CONTENT) {
		return Promise.resolve(null);
	}

	return response.text().then((text) => (text ? JSON.parse(text) : null));
};

const getOptions = (
	url: string,
	userOptions: RequestInit,
	methodOptions: RequestInit = {},
): RequestInit => {
	const defaultOptions = getDefaultOptions(url);

	return {
		...defaultOptions,
		...methodOptions,
		...userOptions,
		headers: {
			...defaultOptions.headers,
			...methodOptions.headers,
			...userOptions.headers,
		},
	};
};

export const performGetRequest = (url: string, options: RequestInit = {}): AsyncResult =>
	fetch(url, getOptions(url, options)).then(processResponse);

export const performPutRequest = (url: string, options: RequestInit = {}): AsyncResult => {
	return fetch(url, getOptions(url, options, putOptions)).then(processResponse);
};

export const performPostRequest = (url: string, options: RequestInit = {}): AsyncResult => {
	return fetch(url, getOptions(url, options, postOptions)).then(processResponse);
};

export const performDeleteRequest = (url: string, options: RequestInit = {}): AsyncResult => {
	return fetch(url, getOptions(url, options, deleteOptions)).then(processResponse);
};

export const performPatchRequest = (url: string, options: RequestInit = {}): AsyncResult => {
	return fetch(url, getOptions(url, options, patchOptions)).then(processResponse);
};
